<template>
  <div class="shift" :style="getStyles(shift.color)" @click="onShiftClick">
    <v-icon color="white">{{ shift.icon }}</v-icon>
  </div>
</template>

<script>
  export default {
    name: "ShiftItem",
    props: {
      shift: Object,
      workplan: Object
    },
    methods: {
      onShiftClick() {
        this.$eventBus.$emit("open:shift-detail", this.shift, this.workplan);
      },
      getStyles(color) {
        return {
          backgroundColor: color
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .shift {
    cursor: pointer;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.other:not(:last-child) {
      margin-bottom: 10px;
    }
    .lock-icon {
      position: absolute;
      top: 1px;
      left: 1px;
    }
  }
</style>
